import {Component} from 'react'

import Copy from './Button.copy'

export default class extends Component {
  static Copy = Copy

  render() {
    return null
  }
}
