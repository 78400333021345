// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-cheatsheets-bash-index-mdx": () => import("./../../../src/pages/cheatsheets/bash/index.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-bash-index-mdx" */),
  "component---src-pages-cheatsheets-expo-index-mdx": () => import("./../../../src/pages/cheatsheets/expo/index.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-expo-index-mdx" */),
  "component---src-pages-cheatsheets-git-index-mdx": () => import("./../../../src/pages/cheatsheets/git/index.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-git-index-mdx" */),
  "component---src-pages-cheatsheets-index-mdx": () => import("./../../../src/pages/cheatsheets/index.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-index-mdx" */),
  "component---src-pages-cheatsheets-linux-index-mdx": () => import("./../../../src/pages/cheatsheets/linux/index.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-linux-index-mdx" */),
  "component---src-pages-cheatsheets-npm-index-mdx": () => import("./../../../src/pages/cheatsheets/npm/index.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-npm-index-mdx" */),
  "component---src-pages-cheatsheets-vscode-index-mdx": () => import("./../../../src/pages/cheatsheets/vscode/index.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-vscode-index-mdx" */),
  "component---src-pages-cheatsheets-yarn-index-mdx": () => import("./../../../src/pages/cheatsheets/yarn/index.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-yarn-index-mdx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-uses-index-mdx": () => import("./../../../src/pages/uses/index.mdx" /* webpackChunkName: "component---src-pages-uses-index-mdx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */),
  "component---src-templates-tags-page-js": () => import("./../../../src/templates/tags-page.js" /* webpackChunkName: "component---src-templates-tags-page-js" */)
}

